<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
//import SearchesByDay from "@/components/search/metrics/searchesByDay";
import DatePicker from "vue2-datepicker";
import helpers from '../../../../helpers'
import Timeline from "@/components/dashboard/search/timeline"
import Autocomplete from "@/components/dashboard/search/autocomplete"
import TopSearches from "@/components/dashboard/search/top-searches"
import TopProducts from "@/components/dashboard/search/top-results"

import {
  searchAnalyticsMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Go Search Analyze",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
    Timeline,
    Autocomplete,
    TopSearches,
    TopProducts
  },
  data() {
    return {
      title: this.$t('search_analyze.title'),
      items: [],
      showModalHost: false,
      showModalProducts:false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      lastSyncDate: '-',
      total_users: 0,
      total_searches: '-',
      total_searches_with_results: '-',
      percetangeWithResults: '',
      query: '',
      total_searches_clicked: '-',
      total_searches_addedToCart: '-',
      ctr:'',
      percetangeAddedToCart:'',
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],
      searchAnalytics: [],
      total_session_searches: '-',
      coversion_rate: '-',
      direct_revenue: '-',
      assisted_revenue: '-',

      filters: [],
      periodLength: 0,
      previousSearchAnalytics: [],
      previous_total_sessions_searches: '-',
      previous_conversion_rate: '-',
      previous_direct_revenue: '-',
      previous_assisted_revenue: '-',
      previous_total_users: '-',
      previous_ctr: '-',
      isLocal: window.config.local ? true : false,
      customers_ctr: 0

    }
  },
  
  mounted() {
    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 
  },
  created() {
    
  },
  methods: {
    ... searchAnalyticsMethods,
    ...helpers,

    formatDate(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    },
    
    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key!='compare'){
            q+= `&key=${f.key}&value=${f.value}`
          }
        });
      }
      return q;
    },

    applyFilter(query){
      
      const loader = this.$loading.show();
      
      if(this.filters.length > 0){
        query+= this.getCustomFilterQuery();
      }

      this.query = query;

      this.getSearchCustomerCount(query).then((res)=>{
        this.total_users = res.data?.length > 0 ? res.data[0].count : 0;
      });

      this.getSearchIndicators(query).then((res)=>{
        this.searchAnalytics = res.data;

        //this.total_users = res.data.length > 0 ? this.getSummaryByKey('customer_search_count', this.searchAnalytics): 0;
        this.total_searches = res.data.length > 0 ? this.getSummaryByKey('search_count', this.searchAnalytics): 0;
        this.total_session_searches = res.data.length > 0 ? this.getSummaryByKey('session_search_count', this.searchAnalytics): 0;
        this.ctr = this.total_session_searches == 0? `0%` :  `${this.parsePercentage( this.getSummaryByKey('session_search_ctr', this.searchAnalytics)/this.total_session_searches * 100)}%` ;
        this.coversion_rate= this.total_session_searches == 0? `0%` :  `${this.parsePercentage( this.getSummaryByKey('session_search_cr', this.searchAnalytics)/this.total_session_searches * 100)}%` ;
        this.direct_revenue=  res.data.length > 0 ? this.getSummaryByKey('direct_revenue', this.searchAnalytics): 0; 
        this.assisted_revenue=  res.data.length > 0 ? this.getSummaryByKey('assisted_revenue', this.searchAnalytics): 0;

        if(this.filters.filter(f=>f.key=='compare').length>0){
         this.getPreviousSearchIndicators();
        }

      }).finally(()=>{
        loader.hide();
      })

      this.getSearchCustomerCTR(query).then((res)=>{

        if(res.data && res.data.length> 0){
          this.customers_ctr = parseFloat(res.data[0].count) || 0;
        }

        })
    },
    getPreviousSearchIndicators (){

      const dates = this.getPreviousPeriod(this.dateFilter[0],this.dateFilter[1]);
      const nextDay = new Date(dates.previousDateTo);
      nextDay.setDate(dates.previousDateTo.getDate() + 1)

      this.previousDates = dates;

      let queryPrevious = `project=${localStorage.getItem('current_project')}`
      queryPrevious+= `&dateFrom=${this.formatDate(dates.previousDateFrom)}&dateTo=${this.formatDate(nextDay)}`;  

      queryPrevious+= this.getCustomFilterQuery();
      
      this.getSearchCustomerCount(queryPrevious).then((res)=>{
        const prev_total_users = res.data?.length > 0 ? res.data[0].count : 0;
        this.previous_total_users = this.calculatePercentageChange(this.total_users, prev_total_users) ;
      });

      this.getSearchIndicators(queryPrevious).then((res)=>{

        this.previousSearchAnalytics = res.data;
        
        const prev_total_session_searches = res.data.length > 0 ? this.getSummaryByKey('session_search_count', this.previousSearchAnalytics): 0;
        this.previous_total_sessions_searches = this.calculatePercentageChange(this.total_session_searches, prev_total_session_searches) ;
        
        const prev_revenue = res.data.length > 0 ? this.getSummaryByKey('direct_revenue', this.previousSearchAnalytics): 0; 
        this.previous_direct_revenue=  this.calculatePercentageChange(this.direct_revenue, prev_revenue) ;

        const prev_assisted_revenue = res.data.length > 0 ? this.getSummaryByKey('assisted_revenue', this.previousSearchAnalytics): 0; 
        this.previous_assisted_revenue=  this.calculatePercentageChange(this.assisted_revenue, prev_assisted_revenue) ;

        const prev_ctr = prev_total_session_searches == 0? 0 : this.getSummaryByKey('session_search_ctr', this.previousSearchAnalytics)/prev_total_session_searches * 100
        const actual_ctr = this.total_session_searches == 0? 0 : this.getSummaryByKey('session_search_ctr', this.searchAnalytics)/this.total_session_searches * 100
        this.previous_ctr=  this.calculatePercentageChange(actual_ctr, prev_ctr) ;

        const prev_cr = prev_total_session_searches == 0? 0 : this.getSummaryByKey('session_search_cr', this.previousSearchAnalytics)/prev_total_session_searches * 100
        const actual_cr = this.total_session_searches == 0? 0 : this.getSummaryByKey('session_search_cr', this.searchAnalytics)/this.total_session_searches * 100
        this.previous_conversion_rate= this.calculatePercentageChange(actual_cr, prev_cr) ;
      });

      
    },
    onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);
      if(filter.key== 'compare'){
        this.previousSearchAnalytics = [];
      }
    },
    onComparePreviousPeriod(){
      if(this.filters.filter(f=>f.key=='compare').length==0){
        this.filters.push({
          badge: this.$t('common.compare_badge'),
          key: 'compare'
        })
        this.applyFilter(this.query);
      }
      this.$refs.actionsDropdown.hide();
    },
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          const timeDifference = newVal[1].getTime() - newVal[0].getTime();
          this.periodLength =  timeDifference / (1000 * 3600 * 24) + 1;

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    

    <div class="row">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-0">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{$t('search_analyze.title')}}</h5>
            <p class="text-muted mb-0">{{$t('search_analyze.subtitle')}}</p>
          </div>

          <div class="text-end w-50">
            <div class="row align-items-center justify-content-end">
              <div class="col-auto ps-0 w-50">
                <div class="flex">
                    <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range
                      :format="'DD.MM.YYYY'" :clearable="false"
                      :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
                    </date-picker>
                </div>
              </div>
              <div class="col-auto ms-0 ps-0">
                <b-dropdown ref="actionsDropdown" ngbDropdown menu-class="dropdown-menu-sm dropdown-menu-end pb-0" right variant="primary">
                    <template v-slot:button-content class="ms-2">
                      <i class="mdi mdi-apps me-1 text-white"></i>
                      <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                        {{$t('common.actions')}}
                        <i class="mdi mdi-chevron-down"></i>
                      </span>
                    </template>
                    
                    <a class="dropdown-item" href="javascript: void(0);" v-if="isLocal">
                      <i class="mdi mdi-filter-variant"></i>
                      {{ $t('common.filter') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onComparePreviousPeriod">
                      <i class="mdi mdi-compare"></i>
                      {{ $t('common.compare') }}
                    </a>
                    <div class="dropdown-divider mb-0"></div>
                  </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="badge bg-primary m-1" v-for="f in filters" :key="f.key">
          <div class="d-flex align-items-center justify-content-between">
            <span class="pe-2">{{ f.key!= 'compare' ? f.badge : `${f.badge} (${periodLength} ${periodLength>1 ? $t('common.days_badge') : $t('common.day_badge')})` }}</span>
              <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
          </div>
        </div>
      </div> 

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('search_analyze.total_users')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('search_analyze.total_users_help')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="total_users">{{parseCountReduced(total_users)}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_total_users === 0,
                        'badge-soft-danger': previous_total_users < 0,
                        'badge-soft-success': previous_total_users > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_total_users > 0 ? '+'  : '' } ${ previous_total_users}%` }}</span>
                  </div>
              </div>
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('search_analyze.sessions')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('search_analyze.sessions_help')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="total_session_searches">{{parseCountReduced(total_session_searches)}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_total_sessions_searches === 0,
                        'badge-soft-danger': previous_total_sessions_searches < 0,
                        'badge-soft-success': previous_total_sessions_searches > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_total_sessions_searches > 0 ? '+'  : '' } ${ previous_total_sessions_searches}%` }}</span>
                  </div>
              </div>
              <div class="col-sm-2 text-center" >
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('search_analyze.ctr')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('search_analyze.ctr_help')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="ctr">{{ctr}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_ctr === 0,
                        'badge-soft-danger': previous_ctr < 0,
                        'badge-soft-success': previous_ctr > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_ctr > 0 ? '+'  : '' } ${ previous_ctr}%` }}</span>
                  </div>
                </div>
              <div class="col-sm-2 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('search_analyze.cr')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('search_analyze.cr_help')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="coversion_rate">{{coversion_rate}}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_conversion_rate === 0,
                        'badge-soft-danger': previous_conversion_rate < 0,
                        'badge-soft-success': previous_conversion_rate > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_conversion_rate > 0 ? '+'  : '' } ${ previous_conversion_rate}%` }}</span>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('search_analyze.direct_revenue')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('search_analyze.direct_revenue_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(direct_revenue)}`">
                    {{ `$${parseMoneyReducedWithLocale(direct_revenue)}` }}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_direct_revenue === 0,
                        'badge-soft-danger': previous_direct_revenue < 0,
                        'badge-soft-success': previous_direct_revenue > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_direct_revenue > 0 ? '+'  : '' } ${ previous_direct_revenue}%` }}</span>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('search_analyze.assisted_revenue')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="$t('search_analyze.assisted_revenue_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(assisted_revenue)}`">
                    {{ `$${parseMoneyReducedWithLocale(assisted_revenue)}` }}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_assisted_revenue === 0,
                        'badge-soft-danger': previous_assisted_revenue < 0,
                        'badge-soft-success': previous_assisted_revenue > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_assisted_revenue > 0 ? '+'  : '' } ${ previous_assisted_revenue}%` }}</span>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="col-xl-12">
        <div class="card">
          <Timeline :query="query" :trendings="searchAnalytics" :compare="filters.filter(f=>f.key=='compare').length > 0" :oldTrendings="previousSearchAnalytics" :periodLength="periodLength"/>
        </div>
      </div>
      <div class="col-xl-4 d-flex">
        <TopSearches :dateFilter="dateFilter"></TopSearches>
      </div>
      <div class="col-xl-8 d-flex">
        <TopProducts :dateFilter="dateFilter"></TopProducts>
      </div>
      <div class="col-xl-4">
        <Autocomplete :dateFilter="dateFilter" :total_users="customers_ctr" />
      </div>
    </div>
    <!--<Funnel/>-->
  </Layout>
</template>

<style scoped>
 .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}

.dropdown-toggle {
  padding-top: 5px !important;
}
</style>