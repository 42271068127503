<script>

import helpers from '../../../helpers'

import {
  searchAnalyticsMethods,
} from "@/state/helpers";


export default {
  components: {

  },
  data() {
    return {
      query: '',
      top_searches: [],
      isLocal: window.config.local ? true : false,
    }
  },
  methods: {
    ...searchAnalyticsMethods,
    ...helpers,

    applyFilter(query){
      this.query = query;

      this.getSearchTopQueries(query).then((res)=>{
        if(res.data && res.data.length> 0){
          this.top_searches = res.data.filter(s=>s.term != '').slice(0, 10);
        }
      })
    },
    onExportClicked(){
      const q = this.query + '&csv=true';

      this.getSearchTopQueries(q).then((res)=>{
        
        const date = new Date();
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('es-ES', options);

        const csvUrl = URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = csvUrl;
        link.setAttribute('download', `top_searches_7days_${formattedDate}.csv`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(csvUrl);
      })
    }
  },
  mounted() {},
  props: {
    dateFilter: Array,
  },
  computed: {
    
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
        
      }
    }
  }
}

</script>

<template>
  <div class="card">
    <div class="top-right-menu">
      <i class="mdi mdi-export-variant font-size-18 text-primary me-1 interact" v-b-popover.hover.left="$t('search_analyze.top_searches_export')" v-on:click="onExportClicked"></i>
      <i class="mdi mdi-help-circle-outline font-size-18 text-primary" v-b-popover.hover.left="$t('search_analyze.top_searches_tooltip')"></i>
    </div>
    
    <div class="card-body pb-0">
      <div class="row h-100">
        <div class="d-flex flex-wrap">
          <div class="me-2">
            <h4 class="card-title mb-0">{{ $t('search_analyze.top_searches_title') }}</h4>
          </div>
        </div>
        
        <p><small>{{ $t('search_analyze.top_searches_subtitle') }}</small></p>
        <div class="table-responsive" v-if="top_searches.length > 0">
          <table class="table b-table table-centered">
            <tbody class="">
              <tr>
                <th class="text-start">{{ $t('search_analyze.top_searches_term') }}</th>
                <th class="text-end">{{ $t('search_analyze.top_searches_count') }}</th>
              </tr>
              <tr v-for="(s, index) in top_searches" :key="index">
                <td class="text-start">{{ s.term }}</td>
                <td class="text-end">{{s.count}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-5 text-center" v-if="top_searches.length == 0">
          <label>{{ $t('search_analyze.top_searches_empty') }}</label>
        </div>
        <div class="text-center mb-3" v-if="isLocal && top_searches.length > 0">
          <a href="/top-searches" class="btn btn-primary btn-sm"> {{ $t('kpi.view_more') }}  <i class="mdi mdi-chevron-right ml-1"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>