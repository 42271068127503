<script>

import helpers from '../../../helpers'

import {
  searchAnalyticsMethods,
} from "@/state/helpers";


export default {
  components: {

  },
  data() {
    return {
      periodLength: 0,
      query: '',
      series: [0,0],
      chartOptions: {
        labels: [this.$t('search_analyze.autocomplete_users_click'), this.$t('search_analyze.autocomplete_users_no_click')],
        colors: ["#5e40bf", "#f1b44c"],
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: false
                },
            }
        }]
      },
      autocomplete_users: 0,
      without_autocomplete_users: 0,
      conversion_rate: 0
    }
  },
  methods: {
    ...searchAnalyticsMethods,
    ...helpers,

    applyFilter(query){
      this.query = query;

      this.getSearchCustomerCTR(query).then((res)=>{
        if(res.data && res.data.length> 0){
          this.autocomplete_users = parseFloat(res.data[0].count) || 0;
        }
        this.without_autocomplete_users = this.total_users - this.autocomplete_users;

        this.series = [this.autocomplete_users, this.without_autocomplete_users];
        
        this.$refs.autocompleteChart?.updateSeries(this.series,true);

        this.getSearchCustomerCR(query).then((rescr)=>{
          if(rescr.data && rescr.data.length> 0){
            this.conversion_rate = this.autocomplete_users == 0 ? 0 : this.parsePercentage(parseFloat(rescr.data[0].count) / this.autocomplete_users * 100);
          }
        });
      })

      
    }

  },
  mounted() {
    
  },
  props: {
    dateFilter: Array,
    total_users: Number
  },
  computed: {
    
  },
  watch: {
    total_users: {
      immediate:true,
      handler(newVal){
        if(newVal >0){
          const nextDay = new Date(this.dateFilter[1]);
          nextDay.setDate(this.dateFilter[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}&autocomplete=true`;  

          this.applyFilter(query)
        }
        
      }
    }
  }
}

</script>

<template>
  <div class="card">
    <i class="mdi mdi-help-circle-outline font-size-18 top-right-menu text-primary" v-b-popover.hover.left="$t('search_analyze.autocomplete_tooltip')"></i>
    <div class="card-body pb-0">
      <div class="row h-100">
        <h4 class="card-title mb-2">{{$t('search_analyze.autocomplete_title')}}</h4>
        <apexchart
          class="apex-charts"
          height="320"
          type="donut"
          dir="ltr"
          :series="series"
          :options="chartOptions"
          ref="autocompleteChart">
        </apexchart>
        <div class="table-responsive">
          <table class="table b-table">
            <tbody class="">
              <tr>
                <td class="text-start">{{$t('search_analyze.autocomplete_users')}}</td>
                <td class="text-end fw-bold" v-b-popover.hover.bottom="autocomplete_users">{{parseCountReduced(autocomplete_users)}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('search_analyze.cr')}}</td>
                <td class="text-end fw-bold">{{conversion_rate}}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  
</style>